<template>
  <div>
    <el-row>
      <el-button type="primary" @click="handleRefresh">刷新token</el-button>
    </el-row>
    <el-divider>AccessToken</el-divider>
    {{accessToken}}
    <el-divider>JsApi_Ticket</el-divider>
    {{jsApi_Ticket}}

  </div>
</template>

<script>
export default {
  data() {
    return {
      accessToken: "",
      jsApi_Ticket: "",
    };
  },
  methods: {
    getAccessToken() {
      let that = this;
      that.axios2.get("OfficialAccount/GetToken").then(function (response) {
        that.accessToken = response.data.data;
      });
    },
    getJsApi_Ticket() {
      let that = this;
      that.axios2.get("OfficialAccount/GetApiTicket").then(function (response) {
        that.jsApi_Ticket = response.data.data;
      });
    },
    handleRefresh() {
      let that = this;
      that.$confirm("确定要刷新吗？", "提示").then(function () {
        const tmpLoading = that.$loading();
        that.axios2.get("OfficialAccount/RefreshAccessToken").then(function (response) {
          if (response.data.code == 101) {
            that.getAccessToken();
            that.getJsApi_Ticket();
          } else {
            that.$alert("刷新失败");
          }
          tmpLoading.close();
        });
      });
    },
  },
  mounted() {
    let that = this;
    that.getAccessToken();
    that.getJsApi_Ticket();
  },
};
</script>

<style>
</style>